import React from 'react';
import dayjs from 'dayjs';
import Status from '../../Status';
import ConfirmCancelButtons from '../../controls/ConfirmCancelButtons';
import { Form, Select, Radio, Input, DatePicker } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CONDITIONS } from '../../../constants/routes';
import { NAME, NAME_MSG, NUMBER, NUMBER_MSG } from "../../../constants/regex";
import { left, select } from "../../../api/actions";

// import utc from 'dayjs/plugin/utc'

// dayjs.extend(utc)

function hasErrors(fieldsError, type) {
    if (type === "Schedule" || type === "Armed" || type === "Disarmed") {
        fieldsError.device = undefined
    }

    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    }
}

function mapStateToProps(state) {
    return { 
        record: state.record,
        processMessage: state.processMessage
    }
}

class ConditionForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            devices: [],
            deviceOptions: [],
            portOptions: [],
            stateOptions: [],
            onvifEventOptions: [],
            error: null,
            isLoaded: false,
            neg: 0,
            duration: 1,
            durationUnit: "minutes",
            durationDi: 0,
            durationDiUnit: "seconds",
            schedule: "always",
            periodUnit: "minutes",
        };

        this.show = this.show.bind(this);
        this.nvrLabel = "NVR-Device"
        this.debug = window.config.DEBUG
    }

    componentDidMount() {

        this.props.left("conditions"); // set initials in redux store
        this.props.form.validateFields(); // disable next button at the beginning
        //const filteredOptions = enabledEvents.filter(event => event.eventtype === "Analytics");
        fetch('enabledEvents.json')  // Adjust the path to your JSON file
        .then(response => {
          if (!response.ok) {
            console.error('Error fetching the JSON data: NOK: ', response.status);
            return [];
          }
          return response.json(); // Parse the JSON data
        })
        .then(data => {
            console.log("data", data)
          // Filter events with "eventtype": "Analytics"
          const filteredOptions = data.filter(event => event.eventtype === "Analytics");
          console.log("filteredOptions", filteredOptions)
          const groupedOptions = filteredOptions.reduce((acc, obj) => {
            const key = obj.category;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          }, {}); // Group the data by category
          //setOptions(filteredOptions); // Update the state with filtered data
          this.setState({ onvifEventOptions: groupedOptions })
        })
        .catch(error => {
          console.error('Error fetching the JSON data:', error);
        });
        

        this.setState({ isLoaded: false }, () => this.show()); // show record entries
    }

    nameChange = value => this.setState({ name: value.target.value });
    negChange = (value) => { this.setState({ neg: value.target.value }); };
    portChange = (value) => { this.setState({ port: value }); };
    stateChange = (value) => this.setState({ state: value });
    scheduleChange = (value) => { this.setState({ schedule: value.target.value }); };
    startChange = (value) => { this.setState({ start: value }); };
    endChange = (value) => { this.setState({ end: value }); };
    durationChange = value => this.setState({ duration: value.target.value })
    durationUnitChange = value =>  this.setState({ durationUnit: value })
    durationDiChange = value => this.setState({ durationDi: value.target.value })
    durationDiUnitChange = value =>  this.setState({ durationDiUnit: value })
    periodChange = value => this.setState({ period: value.target.value })
    periodUnitChange = value =>  this.setState({ periodUnit: value })
    analyticTypeChange = value =>  this.setState({ analyticType: value })
    eventfilterChange = value =>  this.setState({ eventfilter: value })
    onvifEventOptions = value => this.setState({ onvifEventOptions: value })

    deviceChange = value => { 
        if (value) {
            this.setState({ device: value }, () => {
                if (this.state.type === "DigitalInput") {
                    this.resetPortStateDuration()
                    this.initPortAndStateOptions()
                }
            })
        }
    }

    typeChange = value => {
        if (value) {
            this.setState({ type: value }, () => {
                if (this.state.device && 
                    value === "DigitalInput") {
                    this.resetPortStateDuration()
                    this.initPortAndStateOptions()
                    this.initDeviceOptions(true)
                }
    
                if (value === "ImageLoss" || 
                    value === "StreamAvailable" ||
                    value === "Analytics" ||
                    value === "OnvifEvent") {
                    this.initDeviceOptions()
                } 
            })
        }
    }

    resetPortStateDuration() {
        this.setState({
            port: undefined,
            state: undefined,
            durationDi: 0
        })
        this.props.form.setFieldsValue({
            port: undefined,
            state: undefined,
            durationDi: 0
        })
    }

    initPortAndStateOptions() {

        var portOptions = []
        var stateOptions = []
        var digitalInputs = this.state.devices?.find(elem => elem.id === this.state.device?.key)?.digital_inputs; // digital inputs from device if its in devices state (from db)
        var selectedDeviceIsNvr = this.state.device?.label === this.nvrLabel

        if (digitalInputs?.length) {

            for (let i=0; i<digitalInputs.length; i++) {
                portOptions.push(<Select.Option key={i} value={digitalInputs[i]}>{digitalInputs[i]}</Select.Option>)
            }

            stateOptions = [
                <Select.Option key={"active"} value={"active"}>Active</Select.Option>,
                <Select.Option key={"inactive"} value={"inactive"}>Inactive</Select.Option>
            ]
                                    
            if (selectedDeviceIsNvr) {
                stateOptions.push([
                    <Select.Option key={"short_circuit"} value={"short_circuit"}>Short circuit</Select.Option>,
                    <Select.Option key={"cable_break"} value={"cable_break"}>Cable break</Select.Option>
                ])
            }
        } 
        
        this.setState({ 
            portOptions, 
            stateOptions 
        })
    }

    initDeviceOptions(withNvr = false) {

        let devices = [...this.state.devices]
        let deviceOptions = []
        let elem = null
        
        if (withNvr) {
            for (let i=0; i<devices.length; i++) {
                elem = devices[i]
                deviceOptions.push(<Select.Option key={elem.id} value={elem.id}>{!elem.type ? this.nvrLabel : elem.name}</Select.Option>) // rename 1337_N3TC0_NVR to nvrLabel
            }
        } else {
            var devicesWithoutNvr = devices.filter(device => device.type)
            var selectedDeviceIsNvr = this.state.device?.label === this.nvrLabel

            for (let i=0; i<devicesWithoutNvr.length; i++) {
                elem = devicesWithoutNvr[i]
                deviceOptions.push(<Select.Option key={elem.id} value={elem.id}>{elem.name}</Select.Option>)
            }

            if (selectedDeviceIsNvr) {
                this.setState({ device: undefined })
                this.props.form.setFieldsValue({ device: undefined })
            }
        }

        this.setState({ deviceOptions })
    }

    show() {
        const record = this.props.record;

        this.props.processMessage([{
            request: "devicesnosecrets",
            method: "get",
            params: [{ id: 0 }] 
        }])
        .then(res => {
            const devices = res["devicesnosecrets"]?.params
            let device = null
            this.setState({ devices }, () => this.initDeviceOptions(true))

            if (record) {

                this.setState({ 
                    name: record.name,
                    type: record.type,
                    neg: record.neg,
                });

                this.props.form.setFieldsValue({
                    name: record.name,
                    type: record.type,
                    neg: record.neg
                })

                if (record.device) {

                    device = { key: record.device.id, label: record.device.name }
                    this.setState({ device })
                    this.props.form.setFieldsValue({ device })

                } else if (record.type === "DigitalInput") {
                    
                    var nvr = devices.filter(elem => !elem.type)?.[0]
                    device = { key: nvr.id, label: this.nvrLabel }
                    this.setState({ device })
                    this.props.form.setFieldsValue({ device })

                }

                if (record.params) {
    
                    const params = record.params
    
                    switch (record.type) {
                        case "OnvifEvent":
                            if (params.eventfilter) {
                                this.setState({ eventfilter: params.eventfilter });
                            }
                            if (params.duration) 
                                switch (true) {
                                    case (params.duration >= 604800):
                                        this.setState({ 
                                            duration: params.duration/604800, // seconds to weeks
                                            durationUnit: "weeks" 
                                        });
                                        break;
                                    case (params.duration < 604800 && params.duration >= 86400):
                                        this.setState({ 
                                            duration: params.duration/86400, // seconds to days
                                            durationUnit: "days" 
                                        });
                                        break;
                                    case (params.duration < 86400 && params.duration >= 3600):
                                        this.setState({ 
                                            duration: params.duration/3600, // seconds to hours
                                            durationUnit: "hours" 
                                        });
                                        break;
                                    case (params.duration < 3600 && params.duration >= 60):
                                        this.setState({ 
                                            duration: params.duration/60, // seconds to minutes
                                            durationUnit: "minutes" 
                                        });
                                        break;
                                    case (params.duration < 60):
                                        this.setState({ 
                                            duration: params.duration,
                                            durationUnit: "seconds" 
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            this.initDeviceOptions()
                        break;

                        case "Analytics":    
                            if (params.type) {
                                this.setState({ analyticType: params.type });
                            }
                            this.initDeviceOptions()
                            break;
    
                        case "DigitalInput":
                            if (params.port)
                                this.setState({ port: params.port }); 
                            if (params.state)
                                this.setState({ state: params.state });
                            if (params.duration)
                                switch (true) {
                                    case (params.duration >= 60):
                                        this.setState({ 
                                            durationDi: params.duration/60, // seconds to minutes
                                            durationDiUnit: "minutes" 
                                        });
                                        break;
                                    case (params.duration < 60):
                                        this.setState({ 
                                            durationDi: params.duration,
                                            durationDiUnit: "seconds" 
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            this.initPortAndStateOptions()
                            break;
    
                        case "ImageLoss":
                            this.initDeviceOptions()
                            break;

                        case "Schedule":
                            if (params.schedule) 
                                this.setState({ schedule: params.schedule })
                            if (params.start) 
                                this.setState({ start: dayjs.unix(params.start) })
                            if (params.end) 
                                this.setState({ end: dayjs.unix(params.end) })
                            if (params.duration) 
                                switch (true) {
                                    case (params.duration >= 604800):
                                        this.setState({ 
                                            duration: params.duration/604800, // seconds to weeks
                                            durationUnit: "weeks" 
                                        });
                                        break;
                                    case (params.duration < 604800 && params.duration >= 86400):
                                        this.setState({ 
                                            duration: params.duration/86400, // seconds to days
                                            durationUnit: "days" 
                                        });
                                        break;
                                    case (params.duration < 86400 && params.duration >= 3600):
                                        this.setState({ 
                                            duration: params.duration/3600, // seconds to hours
                                            durationUnit: "hours" 
                                        });
                                        break;
                                    case (params.duration < 3600 && params.duration >= 60):
                                        this.setState({ 
                                            duration: params.duration/60, // seconds to minutes
                                            durationUnit: "minutes" 
                                        });
                                        break;
                                    case (params.duration < 60):
                                        this.setState({ 
                                            duration: params.duration,
                                            durationUnit: "seconds" 
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            if (params.period) {
                                switch (true) {
                                    case (params.period >= 604800):
                                        this.setState({ 
                                            period: params.period/604800, // seconds to weeks
                                            periodUnit: "weeks" 
                                        });
                                        break;
                                    case (params.period < 604800 && params.period >= 86400):
                                        this.setState({ 
                                            period: params.period/86400, // seconds to days
                                            periodUnit: "days" 
                                        });
                                        break;
                                    case (params.period < 86400 && params.period >= 3600):
                                        this.setState({ 
                                            period: params.period/3600, // seconds to hours
                                            periodUnit: "hours" 
                                        });
                                        break;
                                    case (params.period < 3600 && params.period >= 60):
                                        this.setState({ 
                                            period: params.period/60, // seconds to minutes
                                            periodUnit: "minutes" 
                                        });
                                        break;
                                    case (params.period < 60):
                                        this.setState({ 
                                            period: params.period,
                                            periodUnit: "seconds" 
                                        });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            break;
                        
                        case "StreamAvailable":
                            this.initDeviceOptions()
                            break;
    
                        default: 
                            break;
                    }
                }
            } 
        })
        .catch(error => {
            console.error(error)
            this.setState({ error })
        })
        .finally(() => this.setState({ isLoaded: true }))
    }
    
    confirm() {

        var { period, periodUnit, type, device, neg, name, state, port, schedule, start, end, duration, durationUnit, durationDi, durationDiUnit, analyticType, eventfilter } = this.state;
        var { record } = this.props;

        if (period) {
            switch (periodUnit) {
                case "minutes":
                    period = parseFloat(period)*60
                    break;
                case "hours":
                    period = parseFloat(period)*3600
                    break;
                case "days":
                    period = parseFloat(period)*86400
                    break;
                case "weeks":
                    period = parseFloat(period)*604800
                    break;
                default:
                    break;
            }
        }

        if (duration) {
            switch (durationUnit) {
                case "minutes":
                    duration = parseFloat(duration)*60
                    break;
                case "hours":
                    duration = parseFloat(duration)*3600
                    break;
                case "days":
                    duration = parseFloat(duration)*86400
                    break;
                case "weeks":
                    duration = parseFloat(duration)*604800
                    break;
                default:
                    break;
            }
        }

        if (durationDi && durationDiUnit === "minutes") {
            durationDi = parseFloat(durationDi)*60
        }

        let req = [{  
            id: record ? record.id : 0, // id of edited record or 0 for new record
            name: name ? name : null, // null instead of empty string e.g. if input was cleared
            device: 
                device 
                && type !== "Schedule" 
                && type !== "Armed" 
                && type !== "Disarmed" 
                && device.label !== this.nvrLabel 
                    ? device.key
                    : null,
            type: type ? type : null,
            neg: neg ? neg : 0,
            params: {
                state: (type === "DigitalInput" && state) ? state : null,
                port: (type === "DigitalInput" && port) ? port : null,
                schedule: (type === "Schedule" && schedule) ? schedule : null,
                start: (type === "Schedule" && schedule === "custom" && start) ? dayjs(start).unix() : null,
                end: (type === "Schedule" && schedule === "custom" && end) ? dayjs(end).unix() : null,
                period: (type === "Schedule" && schedule === "custom" && period) ? parseInt(period) : 0,
                duration: 
                    ((type === "Schedule" && schedule === "custom") || type === "OnvifEvent") 
                    ? duration
                        ? parseInt(duration)
                        : 60
                    : (type === "DigitalInput" && durationDi) 
                        ? parseInt(durationDi)
                        : 0,
                type: (type === "Analytics" && analyticType) ? analyticType : null,
                eventfilter: (type === "OnvifEvent" && eventfilter) ? eventfilter : null
            }
        }]

        this.debug && console.log("set simpleconditions", req)

        // console.log("------------------ LOCAL ------------------")
        // console.log("start", start) //  {$L: 'en', $u: unefined, $d: Wed Jan 17 2024 11:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("start.format()", start.format()) //  2024-01-17T11:00:00+01:00
        // console.log("start.isUTC()", start.isUTC()) // false
        // console.log("start.unix()", start.unix()) // 1705485600
        // console.log("dayjs.unix(1705485600)", dayjs.unix(1705485600)) // {$L: 'en', $u: undefined, $d: Wed Jan 17 2024 11:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("dayjs.unix(1705485600).format()", dayjs.unix(1705485600).format()) // 2024-01-17T11:00:00+01:00
        // console.log("------------------- UTC -------------------")
        // console.log("start.utc()", start.utc()) // {$L: 'en', $u: true, $d: Wed Jan 17 2024 11:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("start.utc().format()", start.utc().format()) // 2024-01-17T10:00:00Z
        // console.log("start.utc().isUTC()", start.utc().isUTC()) // true
        // console.log("start.utc().unix()", start.utc().unix()) // 1705485600
        // console.log("dayjs.unix(1705485600)", dayjs.unix(1705485600)) // {$L: 'en', $u: undefined, $d: Wed Jan 17 2024 11:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("dayjs.unix(1705485600).format()", dayjs.unix(1705485600).format()) // 2024-01-17T11:00:00+01:00
        // console.log("------------------- UTC -------------------")
        // console.log("start.utc(true)", start.utc(true)) // {$L: 'en', $u: true, $d: Wed Jan 17 2024 12:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("start.utc(true).format()", start.utc(true).format()) // 2024-01-17T11:00:00Z
        // console.log("start.utc(true).isUTC()", start.utc(true).isUTC()) // true
        // console.log("start.utc(true).unix()", start.utc(true).unix()) // 1705489200
        // console.log("dayjs.unix(1705489200)", dayjs.unix(1705489200)) // {$L: 'en', $u: undefined, $d: Wed Jan 17 2024 12:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("dayjs.unix(1705489200).format()", dayjs.unix(1705489200).format()) // 2024-01-17T12:00:00+01:00
        // console.log("----------------- OFFSET ------------------")
        // console.log("start.utcOffset()", start.utcOffset()) // 60
        // console.log("start.utc().subtract(start.utcOffset(), 'minute')", start.utc().subtract(start.utcOffset(), 'minute')) // {$L: 'en', $u: true, $d: Wed Jan 17 2024 10:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("start.utc().subtract(start.utcOffset(), 'minute').unix()", start.utc().subtract(start.utcOffset(), 'minute').unix()) // 1705482000
        // console.log("dayjs.unix(1705482000)", dayjs.unix(1705482000)) // {$L: 'en', $u: undefined, $d: Wed Jan 17 2024 10:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("dayjs.unix(1705482000).format()", dayjs.unix(1705482000).format()) // 2024-01-17T10:00:00+01:00
        // console.log("dayjs.unix(1705482000).add(start.utcOffset(), 'minute')", dayjs.unix(1705482000).add(start.utcOffset(), 'minute')) // {$L: 'en', $u: undefined, $d: Wed Jan 17 2024 11:00:00 GMT+0100 (Mitteleuropäische Normalzeit), $y: 2024, $M: 0, …}
        // console.log("dayjs.unix(1705482000).add(start.utcOffset(), 'minute').format()", dayjs.unix(1705482000).add(start.utcOffset(), 'minute').format()) // 2024-01-17T11:00:00+01:00

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "simpleconditions",
                method: "set",
                params: req
            }])
            .then(() => { 
                this.props.history.push(CONDITIONS); // push Devices path to history props to call Devices
                this.props.select(null); // reset record props in redux store
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
        });
    }

    cancel() {
        this.props.select(null)
        this.props.history.push(CONDITIONS)
    }

    componentWillUnmount() {
        this.props.select(null)
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue } = this.props.form;
        const { periodUnit, period, duration, durationUnit, name, port, isLoaded, error, deviceOptions, type, schedule, start, end, neg, device, portOptions, stateOptions, state, durationDi, durationDiUnit, analyticType, eventfilter, onvifEventOptions } = this.state;
        
        // only show error after a field is touched
        const typeError = isFieldTouched('type') && getFieldError('type')
        const deviceError = isFieldTouched('device') && getFieldError('device')
        const nameError = isFieldTouched('name') && getFieldError('name')

        // show form when isLoaded is true and set deviceOptions as select options or show status when error
        if (!error) {
            return <>
                <Form labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }} style={{ margin: "1.5em" }}>

                    <Form.Item label="Name" style={{ marginBottom: 0 }} validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                        {getFieldDecorator('name', {
                            rules: [
                                { required: true, message: "Please enter a name!" }, 
                                { pattern: NAME, message: NAME_MSG }
                            ],
                            onChange: this.nameChange,
                            initialValue: name
                        })(
                            <Input placeholder="Please enter a condition name" disabled={!isLoaded} />           
                        )}
                    </Form.Item>

                    <Form.Item 
                        label="Device" 
                        style={{ marginBottom: 0 }} 
                        validateStatus={deviceError ? 'error' : ''} 
                        help={deviceError || ''}
                    >
                        {getFieldDecorator('device', {
                            rules: [{ required: type !== "Schedule" && type !== "Armed" && type !== "Disarmed", message: "Please select a device!" }],
                            onChange: this.deviceChange,
                            initialValue: device
                        })(
                            <Select 
                                labelInValue 
                                placeholder="Please select a device"
                                disabled={!isLoaded || type === "Schedule"}
                            >
                                {deviceOptions}
                            </Select>               
                        )}
                    </Form.Item>

                    <Form.Item label="Type" style={{ marginBottom: 0 }} validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
                        {getFieldDecorator('type', {
                            rules: [{ required: true, message: "Please select a type!" }],
                            onChange: this.typeChange,
                            initialValue: type,
                        })(
                            <Select placeholder="Please select a condition" disabled={!isLoaded}>
                                <Select.Option value="Analytics">Analytics</Select.Option>
                                <Select.Option value="DigitalInput">Digital Input</Select.Option>
                                <Select.Option value="ImageLoss">Image Loss</Select.Option>
                                <Select.Option value="Schedule">Schedule</Select.Option>
                                <Select.Option value="StreamAvailable">Stream Available</Select.Option>
                                <Select.Option value="Armed">Armed</Select.Option>
                                <Select.Option value="Disarmed">Disarmed</Select.Option>
                                <Select.Option value="OnvifEvent">Onvif Events</Select.Option>
                            </Select>        
                        )}
                    </Form.Item>

                    {(() => {
                        switch (type) {
                            case 'Analytics':
                                return (
                                    <Form.Item label="Analytic" style={{ marginBottom: 0 }} >
                                        {getFieldDecorator('analyticType', {
                                            rules: [{ required: true, message: "Please select an analytic type!" }],
                                            onChange: this.analyticTypeChange,
                                            initialValue: analyticType
                                        })(
                                            <Select 
                                                placeholder={device ? "Please select an analytic" : "Please select a device"} 
                                                disabled={!isLoaded || !device}
                                            >
                                                <Select.Option value="itemLeftBehind">Item left behind</Select.Option>
                                                <Select.Option value="crossedLine">Crossed line</Select.Option>
                                                <Select.Option value="enter">Object enters region</Select.Option>
                                                <Select.Option value="appear">Object appears in region</Select.Option>
                                                <Select.Option value="exit">Object leaves region</Select.Option>
                                                <Select.Option value="disappear">Object disappears in region</Select.Option>
                                            </Select>        
                                        )}
                                    </Form.Item>
                                )

                                // Todo: read allowed values from file
                                case 'OnvifEvent':
                                return <>
                                    <Form.Item label="Onvif Event" style={{ marginBottom: 0 }} >
                                        {getFieldDecorator('eventfilter', {
                                            rules: [{ required: true, message: "Please select an event type!" }],
                                            onChange: this.eventfilterChange,
                                            initialValue: eventfilter
                                        })(
                                            <Select 
                                                placeholder={device ? "Please select an event type" : "Please select a device"} 
                                                disabled={!isLoaded || !device}
                                            >
                                                    {Object.keys(onvifEventOptions).map(category => (
                                                                <Select.Option key={category} value={category}>
                                                                    {category}
                                                                </Select.Option>
                                                            ))}
                                                {/* <Select.Option value="Motion">Motion detected</Select.Option>
                                                <Select.Option value="Crossed">Crossed line</Select.Option>
                                                <Select.Option value="ObjectsInside">Object inside region</Select.Option>
                                                <Select.Option value="ObjectsOutside">Object outside region</Select.Option>
                                                <Select.Option value="Loitering">Object loitering</Select.Option>
                                                <Select.Option value="Audio">Audio detected</Select.Option> */}
                                            </Select>        
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Duration" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('duration', {
                                        rules: [
                                            { required: true, message: "Please enter a duration!" },
                                            { pattern: NUMBER, message: NUMBER_MSG }
                                        ],
                                        onChange: this.durationChange,
                                        initialValue: duration
                                    })(
                                        <Input 
                                            placeholder="Please enter a duration" 
                                            style={{ width: "100%" }} 
                                            disabled={!isLoaded}
                                            addonAfter={(
                                                <Select 
                                                    onChange={this.durationUnitChange}
                                                    value={durationUnit}
                                                    style={{ width: "100px" }}
                                                >
                                                    <Select.Option value="seconds">seconds</Select.Option>
                                                    <Select.Option value="minutes">minutes</Select.Option>
                                                    <Select.Option value="hours">hours</Select.Option>
                                                    <Select.Option value="days">days</Select.Option>
                                                    <Select.Option value="weeks">weeks</Select.Option>
                                                </Select>
                                            )}
                                        />
                                    )}
                                </Form.Item>

                            </>
                            
                            case 'DigitalInput':
                                return <>
                                    <Form.Item label="Port" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('port', {
                                            rules: [{ required: true, message: "Please select a port!" }],
                                            onChange: this.portChange,
                                            initialValue: port
                                        })(
                                            <Select 
                                                placeholder={device ? (portOptions.length ? "Please select a port" : "Device has no inputs") : "Please select a device"} 
                                                disabled={!isLoaded || !device || !portOptions.length}
                                            >
                                                {portOptions}
                                            </Select>                                         
                                        )}
                                    </Form.Item>

                                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('state', {
                                            rules: [{ required: true, message: "Please select a state!" }],
                                            onChange: this.stateChange,
                                            initialValue: state
                                        })(
                                            <Select 
                                                placeholder={device ? (port ? "Please select a state" : (portOptions.length ? "Please select a port" : "Device has no inputs")) : "Please select a device"} 
                                                disabled={!isLoaded || !device || !portOptions.length || !port}
                                            >
                                                {stateOptions}
                                            </Select>       
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Duration" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('durationDi', {
                                            onChange: this.durationDiChange,
                                            initialValue: durationDi,
                                            rules: [{ pattern: NUMBER, message: NUMBER_MSG }]
                                        })(
                                            <Input 
                                                placeholder="Please enter a duration" 
                                                style={{ width: "100%" }} 
                                                disabled={!isLoaded || !device || !portOptions.length || !port || !state}
                                                addonAfter={(
                                                    <Select 
                                                        onChange={this.durationDiUnitChange}
                                                        value={durationDiUnit}
                                                        style={{ width: "100px" }}
                                                    >
                                                        <Select.Option value="seconds">seconds</Select.Option>
                                                        <Select.Option value="minutes">minutes</Select.Option>
                                                    </Select>
                                                )}
                                            />
                                        )}
                                    </Form.Item>
                                </>

                            case 'Schedule':
                                return <Form.Item label="Schedule" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('schedule', {
                                            onChange: this.scheduleChange,
                                            initialValue: schedule
                                        })(
                                            <Radio.Group disabled={!isLoaded} style={{ width: "100%" }} buttonStyle="solid">
                                                <Radio.Button value="always" style={{ width: "50%", textAlign: "center" }}>Always</Radio.Button>
                                                <Radio.Button value="custom" style={{ width: "50%", textAlign: "center" }}>Custom</Radio.Button>
                                            </Radio.Group>                
                                        )}
                                    </Form.Item>
                                    
                            default:
                                return null;
                            }
                    })()}

                    {(() => {
                        if (schedule === "custom" && type === "Schedule") {
                            return <>
                                <Form.Item label="Start" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('start', {
                                        rules: [{ required: true, message: "Please select a start time!" }],
                                        onChange: this.startChange,
                                        initialValue: start
                                    })(
                                        <DatePicker
                                            showTime={{ format: 'HH:mm', hideDisabledOptions: true }}
                                            format="YYYY-MM-DD HH:mm"
                                            allowClear={false}
                                            placeholder='Please select a start time'
                                            style={{ width: "100%" }}                          
                                            disabled={!isLoaded} 
                                            disabledDate={current => current < dayjs().subtract(1, 'day')}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="End" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('end', {
                                        rules: [{ required: true, message: "Please select a end time!" }],
                                        onChange: this.endChange,
                                        initialValue: end
                                    })(
                                        <DatePicker
                                            showTime={{ format: 'HH:mm', hideDisabledOptions: true }}
                                            format="YYYY-MM-DD HH:mm"
                                            allowClear={false}
                                            placeholder='Please select a end time'
                                            style={{ width: "100%" }}                          
                                            disabled={!isLoaded} 
                                            disabledDate={current => current < dayjs().subtract(1, 'day') || current < start}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="Period" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('period', {
                                        onChange: this.periodChange,
                                        initialValue: period,
                                        rules: [{ pattern: NUMBER, message: NUMBER_MSG }]
                                    })(
                                        <Input 
                                            placeholder="Please enter a period" 
                                            style={{ width: "100%" }} 
                                            disabled={!isLoaded}
                                            addonAfter={(
                                                <Select 
                                                    onChange={this.periodUnitChange}
                                                    value={periodUnit}
                                                    style={{ width: "100px" }}
                                                >
                                                    <Select.Option value="seconds">seconds</Select.Option>
                                                    <Select.Option value="minutes">minutes</Select.Option>
                                                    <Select.Option value="hours">hours</Select.Option>
                                                    <Select.Option value="days">days</Select.Option>
                                                    <Select.Option value="weeks">weeks</Select.Option>
                                                </Select>
                                            )}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label="Duration" style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('duration', {
                                        rules: [
                                            { required: true, message: "Please enter a duration!" },
                                            { pattern: NUMBER, message: NUMBER_MSG }
                                        ],
                                        onChange: this.durationChange,
                                        initialValue: duration
                                    })(
                                        <Input 
                                            placeholder="Please enter a duration" 
                                            style={{ width: "100%" }} 
                                            disabled={!isLoaded}
                                            addonAfter={(
                                                <Select 
                                                    onChange={this.durationUnitChange}
                                                    value={durationUnit}
                                                    style={{ width: "100px" }}
                                                >
                                                    <Select.Option value="seconds">seconds</Select.Option>
                                                    <Select.Option value="minutes">minutes</Select.Option>
                                                    <Select.Option value="hours">hours</Select.Option>
                                                    <Select.Option value="days">days</Select.Option>
                                                    <Select.Option value="weeks">weeks</Select.Option>
                                                </Select>
                                            )}
                                        />
                                    )}
                                </Form.Item>
                            </>
                        }
                    })()}

                    <Form.Item label="Invert Condition" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('neg', {
                            onChange: this.negChange,
                            initialValue: neg
                        })(
                            <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                <Radio.Button value={1} style={{ width: "50%", textAlign: "center" }}>True</Radio.Button>
                                <Radio.Button value={0} style={{ width: "50%", textAlign: "center" }}>False</Radio.Button>
                            </Radio.Group>                
                        )}
                    </Form.Item>
                </Form>

                <ConfirmCancelButtons
                    loading={!isLoaded} 
                    disabled={hasErrors(getFieldsError(),type) 
                        || (type === "Schedule" && schedule === "custom" && (!start || !end || !getFieldValue("duration")))
                        || (type === "DigitalInput" && (!getFieldValue("port") || !getFieldValue("state")))
                        || ((type === "ImageLoss" || type === "StreamAvailable") && !getFieldValue("device"))
                        || (type === "Analytics" && (!getFieldValue("analyticType") || !getFieldValue("device")))
                        || (type === "OnvifEvent" && (!getFieldValue("eventfilter") || !getFieldValue("device")))
                    } 
                    onConfirm={this.confirm.bind(this)} 
                    onCancel={this.cancel.bind(this)}
                />
            </>
        } else {
            return <Status is={error} back={CONDITIONS} />
        }
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(ConditionForm)));